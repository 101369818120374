import userTypes, { USER_TYPE } from "@elsa-esa/lib/constants/userTypes";
import { commonError } from "src/js/helpers/commonError";
import { setExternalValidation } from "src/js/helpers/form";
import { hideLoader } from "src/js/helpers/loader";
import fetch, { setToken } from "@elsa-esa/lib/helpers/fetch";

export type AuthData = {
  user: {
    type_user: USER_TYPE
  }
  token: {
    access_token: string
  }
}

export const redirectUser = (user: AuthData['user']) => {
  const { type_user } = user;
  const userType = userTypes.dict[type_user];
  if (!userType) return alert('Неизвестный тип пользователя');

  let newHref = userType.baseUrl;
  if (window.URLSearchParams) {
    const params = new window.URLSearchParams(location.search);
    const redirect = params.get('redirect');
    if (redirect && redirect.indexOf(userType.baseUrl) === 0) newHref = redirect;
  }
  window.location.href = newHref;
};

const error = (onError?: () => any) => {
  delete localStorage.token;
  if (onError) onError();
};

const getToken = () => {
  let { search } = window.location;
  if (search) {
    const token = search
      .slice(1)
      .split('&')
      .reduce((token, part) => {
        if (!token) {
          const [param, value] = part.split('=');
          if (param === 'token') token = value;
        }
        return token;
      }, null);
    if (token) localStorage.token = token;
  }
  return localStorage.token;
};

export const checkUser = (onError?: () => any) => {
  const token = getToken();
  if (!token) return error(onError);
  setToken(token);
  fetch('GET', 'auth/user').then(
    (data) => {
      if (!data) return error(onError);
      redirectUser(data);
    },
    (code) => {
      if (code === 0 || code === -1) return;
      error(onError);
    }
  );
};

const tryAuthUser = (data: AuthData) => {
  if (!data) return commonError();
  const { token, user } = data;
  if (!token || !user) return commonError();
  localStorage.token = token.access_token;
  redirectUser(user);
};

export const authUser = (formEl: HTMLFormElement, fields: string[], data: AuthData) => {
  const result = tryAuthUser(data);
  if (result) {
    hideLoader(formEl);
    setExternalValidation(formEl, fields, result);
  }
};
