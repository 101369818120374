import { getById } from "src/js/helpers/dom";

const activeClass = 'container__loader--active';
const loaderEl = getById('loader');

export const showLoader = (formEl: HTMLElement) => {
  const inputs = formEl.querySelectorAll<HTMLInputElement | HTMLButtonElement>('input, button');
  const loader = formEl.querySelector<HTMLDivElement>('.loader');
  inputs.forEach((el) => el.disabled = true)
  loader.style.display = 'block';

  // loaderEl.classList.add(activeClass);
}
export const hideLoader = (formEl: HTMLElement) => {
  const inputs = formEl.querySelectorAll<HTMLInputElement | HTMLButtonElement>('input, button')
  const loader = formEl.querySelector<HTMLDivElement>('.loader');

  inputs.forEach((el) => el.disabled = false)
  loader.style.display = '';

  // loaderEl.classList.remove(activeClass);
}
